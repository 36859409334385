<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3"> تقرير الحجز في المواد    </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                      <!-- exceptional attendance row -->
                        <div class="row align-center ex-row">
                   <div class="mb-3 col-lg-3">
                    <label>المادة</label>
                    <multiselect
                        class='required form-control'
                        id='student'
                        v-model="selectedSubject"
                        :options='subjects'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المادة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                 <div class="mb-3 col-lg-3">
                    <label>   الفرقة</label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedGrade"
                        :options='grades'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الفرقة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                 <div class="mb-3 col-lg-3">
                    <label>   المجموعة</label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedGroup"
                        :options='groups'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المجموعة"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                 <div class="mb-3 col-lg-3">
                    <label>   المدرس</label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedInstructor"
                        :options='instructors'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المدرس"
                        label='name'
                        :custom-label="customLabel"
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-lg-4">
                    <label> اسم الطالب</label>
                    <vmd-input
                    id=""
                    type="text"
                    v-model="studentName"
                  />
                    </div>
                    <div class="mb-3 col-lg-4">
                    <label> رقم الطالب</label>
                    <vmd-input
                    id=""
                    type="number"
                    v-model="studentNum"
                  />
                    </div>
                 <div class="text-center col-lg-1 ">
                  <vmd-button
                    class="my-4 mb-2 width height action-btn"
                    variant="gradient"
                    color="primary"
                    @click="filterData()"
                    >  فلتر</vmd-button
                  >
                </div>
                <div class="text-center col-lg-1 ">
                  <vmd-button
                    class="my-4 mb-2 width height action-btn"
                    variant="gradient"
                    color="danger"
                    @click="clearData()"
                    >  مسح</vmd-button
                  >
                </div>
                <div class="text-center col-lg-1">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="info"
                    @click="printReport()"
                    >  طباعة</vmd-button
                  >
                </div>
                  <div class="text-center col-lg-1 ">
                  <vmd-button
                    class="my-4 mb-2 width action-btn"
                    variant="gradient"
                    color="warning"
                    @click="exportTableToExcel('myTable', fileName )"
                    >  اكسل</vmd-button
                  >
                </div>
              </div>
              <div  style=" margin-top: 40px;direction:rtl">
              <div class='row times' style=" margin-top: 40px;direction:rtl">
                <div class="col-md-4" >
                  <h5> تقرير حجز الطلاب في المواد
                    <span class="green">{{grade}}</span></h5>
                </div>
                <div class="col-md-4" >
                  <h5>المادة : <span class="green">{{selectedSubject?selectedSubject.name :'جميع المواد'}}</span></h5>
                </div>
              </div>
            <div class="row align-center" style=" margin-top: 40px;direction:rtl !important" >
              <div class="text-center col-lg-12 overflow-x" style="direction:rtl">
                <table id="myTable" Class="table" style="direction:rtl">
                  <thead>
                    <tr>
                       <th >#</th>
                      <th >رقم الطالب </th>
                        <th>اسم الطالب</th>
                        <th>الهاتف</th>
                         <th> المادة</th>
                        <th>الفرقة</th>
                        <th>المجموعة</th>
                        </tr>

                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in reservations" :key="item.id">
                      <td>{{index+1}}</td>
                       <td>{{item?.student?.number}}</td>
                      <td>{{item.student?.full_name}}</td>
                      <td>{{item.student?.mobile}}</td>
                       <td>{{item.subject?.name}}</td>
                      <td>{{item.student?.grade?.name}}</td>
                      <td>{{item.student?.group?.name}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import * as XLSX from 'xlsx'
// import swal from 'sweetalert'
import $ from 'jquery'
const today = new Date()
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
const time = today.getHours() + ':' + today.getMinutes()
const CurrentDateTime = date + ' ' + time
export default {
  name: 'ReservationReport',
  components: {
    VmdButton,
    VmdInput
  },
  data () {
    return {
      tableDate: today,
      subjects: [],
      selectedSubject: '',
      grades: [],
      selectedGrade: '',
      groups: [],
      selectedGroup: '',
      reservations: [],
      studentNum: '',
      studentName: '',
      fileName: `تقرير حجز الطلاب للمادة ${CurrentDateTime}`,
      instructors: [],
      selectedInstructor: '',
      grade: '',
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}grades`).then(response => {
      this.grades = response.data.data
    })
    this.$http.get(`${this.$hostUrl}groups`).then(response => {
      this.groups = response.data.data
    })
    this.$http.get(`${this.$hostUrl}subjects`).then(response => {
      this.subjects = response.data.data
    })
    this.$http.get(`${this.$hostUrl}instructors`).then(response => {
      this.instructors = response.data.data
    })
    this.$http.get(`${this.$hostUrl}reservations`).then(response => {
      this.reservations = response.data.data
      this.grade = response.data.data.grade
    })
  },
  methods: {
    filterData () {
      this.$http.get(`${this.$hostUrl}reservations?subject_id=${this.selectedSubject?.id || ''}&grade_id=${this.selectedGrade?.id ?? ''}&group_id=${this.selectedGroup?.id ?? ''}&number=${this.studentNum ?? ''}&name=${this.studentName ?? ''}&instructor_id=${this.selectedInstructor?.id ?? ''} `).then(response => {
        this.reservations = response.data.data
        this.grade = response.data.data.grade
      })

      $.each(this.students, (key, val) => {
        this.studentsFile.push({
          رقم: val.number,
          الاسم: val.full_name,
          الهاتف: val.mobile,
          المجموعة: val.group_name,
          رقم_الدورة: 5,
          المسدد: val.paid,
          المتبقي: val.remain

        })
      })
    },
    clearData () {
      this.selectedGrade = ''
      this.selectedGroup = ''
      this.selectedInstructor = ''
      this.selectedSubject = ''
      this.studentName = ''
      this.studentNum = ''
      this.$http.get(`${this.$hostUrl}reservations`).then(response => {
        this.reservations = response.data.data
        this.grade = response.data.data.grade
      })
    },
    exportTableToExcel (tableID, filename = '') {
      console.log('export excel all')
      var wb = XLSX.utils.table_to_book(document.getElementById(tableID))
      var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      var blob = new Blob([wbout], { type: 'application/octet-stream' })
      var url = URL.createObjectURL(blob)
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = filename + '.xlsx'
      a.click()
      setTimeout(function () {
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    },
    printReport () {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      setTimeout(this.$htmlToPaper('print-report', options), 10000)
      $('.card-footer').show()
    }
  }
}
</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.width{
  width:100% !important
}
.height{
  height: 45px !important;
}

</style>
